import axios from 'axios'
import { Message } from 'element-ui'
// import store from '@/store'
const host = window.location.hostname

let API_BASE_URL
var hostname = window.location.hostname;  
console.log('https://'+hostname); // 例如：www.example.com

if (host === 'localhost') {
  // 开发环境
  API_BASE_URL = '/api'
} else {
  // 生产环境
  API_BASE_URL = 'https://'+hostname
}

const service = axios.create({
  baseURL: API_BASE_URL, // api的base_url    process.env.BASE_API,
  timeout: 10000 // request timeout
})
export const API_URL = 'https://'+hostname
// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers['device'] = localStorage.getItem('keyTitle') ? localStorage.getItem('keyTitle') : 'Android' // 一个是Android 一个是Ios
    // config.headers['Access-Control-Allow-Origin'] = '*'
    // Do something before request is sent
    //  if (store.getters.token) {
    // config.headers['Authorization'] = 'Bearer ' + getToken('chunkLAXUser-Token') // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    //  }
    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// // 判断token失效跳转
// service.interceptors.response.use(response => {
//   if (response) {
//     switch (response.data.code) {
//       // case 1001: // 与后台约定登录失效的返回码,根据实际情况处理
//       // RemoveTelPhone() // 删除用户ID
//       // removeToken() // 删除用户登录验证的key值，即token值
//       // this.$router.push({
//       //   path: '/login'
//       // })
//       // console.log(response.data)
//     }
//     console.log(response.data)
//   }
//   return response
// }, error => {
//   return Promise.reject(error.response.data) // 返回接口返回的错误信息
// })

// respone interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
